<div
  class="begoalslist-layout content-container d-flex flex-column"
  [ngClass]="{
    boxShadow: applyBoxShadow
  }"
>
<form [formGroup]="form">
<div class="form-group input-box w-50" *ngIf="!editClient">


  <label class="label">Year</label>
  <input type="text" formControlName="year" class="form-control customInput" id="year" [ngClass]="{
      errorInputBorder:
        (form.controls['year'].hasError('required') || form.controls['year'].hasError('min') || form.controls['year'].hasError('max')) &&
        form.controls['year'].touched,
      'pointer-events-none': !!editClient
    }" placeholder="YYYY" (keydown.space)="utilService.restrictLeadingSpace($event)" autocomplete="off"
    maxlength="4" onlynumber  (paste)="(false)" />
 
  <div class="d-flex pt-2" id="year-error-message" [ngClass]="
      (form.controls['year'].hasError('required') || form.controls['year'].hasError('min') || form.controls['year'].hasError('max')) &&
      form.controls['year'].touched
        ? 'visible'
        : 'invisible'
    ">
    <img src="../../assets/images/exclamation-circle.svg" alt="Error" class="align-self-start" />
    <span class="error px-1" *ngIf="form.controls['year'].hasError('required')">Year is vereist</span>
    <span class="error px-1" *ngIf="form.controls['year'].hasError('min') ||  form.controls['year'].hasError('max')">Year can be one of 2021, 2022, or 2023.</span>
  </div>
</div>
<div class="text">
  <label class="subLabel">CSRD 'niet plichtig'</label>
  <p class="description">
    Wil je de CSRD 'niet plichtig' module activeren?
  </p>
  <div class="d-flex radio align-items-center mt-3 mb-3">
    <p class="me-5 mb-0">
      <input type="radio" formControlName="csrdBasis" [value]="'NONE'" [checked]="csrdBasis === 'NONE'" id="csrdBasis_none" (change)="handleCSRDAnswer('NONE')" />
      <label for="csrdBasis_none" class="answer">Niet actief</label>
    </p>
    <p class="mb-0">
      <input type="radio" formControlName="csrdBasis" [value]="'BASIS'" [checked]="csrdBasis === 'BASIS'" id="csrdBasis_basis" (change)="handleCSRDAnswer('BASIS')" />
      <label for="csrdBasis_basis" class="answer">Basis</label>
    </p>
    <p class="mb-0">
      <input type="radio" formControlName="csrdBasis" [value]="'NARRATIVE'" [checked]="csrdBasis === 'NARRATIVE'" id="csrdBasis_narrative" (change)="handleCSRDAnswer('NARRATIVE')" />
      <label for="csrdBasis_narrative" class="answer">Narrative</label>
    </p>
  </div>

</div>
</form>
<div class="text" *ngIf="editClient">
  <label class="subLabel">Year:  {{year}}</label>
</div>
  <div class="text">
    <label class="subLabel">Break Even Goals</label>
    <p class="description">
      Selecteer de assessments die de klant heeft afgenomen. Wanneer je geen
      assessments selecteert zal de klant alleen de risicoscan zien. Je kunt ook
      assessments deselecteren maar alleen zolang de klant nog niet gestart is
      met dit assessment.
    </p>
    <p class="description">
      {{
        !updatedBEGoalsList?.length
          ? "Er is nog geen assessment geselecteerd."
          : "Er zijn " +
          updatedBEGoalsList?.length +
            " assessment(s) geselecteerd."
      }}
    </p>
  </div>

  <checkbox-group
    [applyBoxShadow]="applyBoxShadow"
    (onToggleItem)="onToggleItem($event)"
    [itemsList]="BEGoalsList"
  ></checkbox-group>
</div>
