import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';
import {
  NgbAccordionModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavBarComponent } from '../components/nav-bar/nav-bar.component';
import { HeaderComponent } from '../components/header/header.component';
import { ModalService } from './services/modal.service';
import { LoaderComponent } from '../components/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { SubmitConfirmationModalComponent } from '../components/modals/submit-confirmation-modal/submit-confirmation-modal.component';
import { ErrorComponent } from '../components/error/error.component';
import { ErrorService } from './services/error.service';
import { ToastComponent } from '../components/toast/toast.component';
import { GoBackComponent } from '../components/go-back/go-back.component';
import { SelectSearchableComponent } from '../components/select-searchable/select-searchable.component';
import { pipes } from './pipes';
import { directives } from './directives';
import { TableComponent } from '../components/table/table.component';
import { ScrollableTableComponent } from '../components/scrollable-table/scrollable-table.component';
import { AnswersNavigationComponent } from '../components/answers-navigation/answers-navigation.component';
import { AccordionComponent } from '../components/accordion/accordion.component';
import { DeepdiveChartsComponent } from '../components/deepdive-charts/deepdive-charts.component';
import { ClientFormComponent } from '../components/client-form/client-form.component';
import { NewClientFormComponent } from '../components/new-client-form/new-client-form.component';
import { RiskScanGoalsComponent } from '../components/risk-scan-goals/risk-scan-goals.component';
import { RiskScanGoalDetailsComponent } from '../components/risk-scan-goal-details/risk-scan-goal-details.component';
import { BeGoalsIndicationsComponent } from '../components/be-goals-indications/be-goals-indications.component';
import { BeGoalRiskScanDetailsComponent } from '../components/be-goal-risk-scan-details/be-goal-risk-scan-details.component';
import { RiskScanLegendaComponent } from '../components/risk-scan-legenda/risk-scan-legenda.component';
import { RiskLevelsComponent } from '../components/risk-levels/risk-levels.component';
import { GenericConsultantEmailComponent } from '../components/generic-consultant-email/generic-consultant-email.component';
import { BeGoalSdgComponent } from '../components/be-goal-sdg/be-goal-sdg.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { ModalComponent } from '../components/modals/modal/modal.component';
import { SelectComponent } from '../components/select/select.component';
import { TabComponent } from '../components/tab/tab.component';
import { DeepdiveQuestionsComponent } from '../components/deepdive-questions/deepdive-questions.component';
import { DeepdiveQuestionsMultipleLocationsComponent } from '../components/deepdive-questions-multiple-locations/deepdive-questions-multiple-locations.component';
import { DeepdiveTableQuestionsComponent } from '../components/deepdive-table-questions/deepdive-table-questions.component';
import { TextEditorComponent } from '../components/text-editor/text-editor.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgbDateParserFormatterExtProvider } from '../date-picker/ index';
import { DeepdiveAssessmentQuestionsComponent } from '../components/deepdive-assessment-questions/deepdive-assessment-questions.component';
import { HomeNavigationComponent } from '../components/home-navigation/home-navigation.component';
import { LogoutComponent } from '../components/logout/logout.component';
import { AssessmentCommentsTabComponent } from '../components/assessment-comments-tab/assessment-comments-tab.component';
import { NgbDateCustomParserFormatter } from './services/custom';
import { DeepdivePerformanceComponent } from '../components/deepdive-performance/deepdive-performance.component';
import { DeepdiveRegulationsComponent } from '../components/deepdive-regulations/deepdive-regulations.component';
import { DeepdiveGoalComponent } from '../components/deepdive-goal/deepdive-goal.component';
import { DeepdiveActionComponent } from '../components/deepdive-action/deepdive-action.component';
import { DeepdiveNotesComponent } from '../components/deepdive-notes/deepdive-notes.component';
import { DeepdiveAssessmentResultsComponent } from '../components/deepdive-assessment-results/deepdive-assessment-results.component';
import { NgChartsModule } from 'ng2-charts';
import { ChartComponent} from '../components/chart-x/chart.component';
import {CheckboxGroupComponent} from '../components/checkbox-group/checkbox-group.component'
import { BEGoalsList } from '../components/be-goals-list/be-goals-list.component';
import { MarkdownIt } from '../components/markdown-it/markdown-it.component';
import { SearchableDrowndownComponent } from '../components/searchable-dropdown/searchable-dropdown.component';
import { FilterComponent } from '../components/filter-component/filter.component';
import { PrintableDashboardComponent } from '../components/printable-dashboard/printable-dashboard.component';
import { PrintableChartComponent } from '../components/printable-dashboard/printable-chart/printable-chart.component';
import { StepService } from './services/step.service';
import { StepIndicatorComponent } from '../components/step-indicator/step-indicator.component';
import { AddLocationModalComponent } from '../components/add-location-modal/add-location-modal.component';
import { SortingWidgetComponent } from '../components/sorting-widget/sorting-widget.component';
import { EditDropDownComponent } from '../components/edit-dropdown/edit-dropdown.component';
import { BeGaolsStatusCheckboxGroupComponent } from '../components/be-goals-status-checkbox-group/be-goals-status-checkbox-group.component';
import { BarChartComponent } from '../components/chart/bar-chart/bar-chart.component';
import { PieChartComponent } from '../components/chart/pie-chart/pie-chart.component';
import { StackedBarChartComponentAdvance } from '../components/chart/stacked-bar-chart-advance/stacked-bar-chart-advance.component';
import { StackedBarChartComponent } from '../components/chart/stacked-bar-chart/stacked-bar-chart.component';
import { ChartComponentV2} from '../components/chart/chart.component';
import { NgxChartsModule } from 'ngx-charts-extended';
import { CSRDBox } from '../components/csrd-box/csrd-box.component';

@NgModule({
  declarations: [
    NavBarComponent,
    HeaderComponent,
    SubmitConfirmationModalComponent,
    LoaderComponent,
    ErrorComponent,
    ToastComponent,
    GoBackComponent,
    SelectSearchableComponent,
    TableComponent,
    ScrollableTableComponent,
    AnswersNavigationComponent,
    AccordionComponent,
    ClientFormComponent,
    NewClientFormComponent,
    DeepdiveChartsComponent,
    RiskScanGoalsComponent,
    RiskScanGoalDetailsComponent,
    BeGoalsIndicationsComponent,
    BeGoalRiskScanDetailsComponent,
    RiskScanLegendaComponent,
    RiskLevelsComponent,
    GenericConsultantEmailComponent,
    BeGoalSdgComponent,
    SpinnerComponent,
    ContextMenuComponent,
    SelectComponent,
    ModalComponent,
    TabComponent,
    CSRDBox,
    DeepdiveQuestionsComponent,
    DeepdiveQuestionsMultipleLocationsComponent,
    DeepdiveTableQuestionsComponent,
    TextEditorComponent,
    DeepdiveAssessmentQuestionsComponent,
    LogoutComponent,
    HomeNavigationComponent,
    AssessmentCommentsTabComponent,
    DeepdivePerformanceComponent,
    DeepdiveRegulationsComponent,
    DeepdiveGoalComponent,
    DeepdiveActionComponent,
    DeepdiveNotesComponent,
    DeepdiveAssessmentResultsComponent,
    ChartComponent,
    ChartComponentV2,
    CheckboxGroupComponent,
    MarkdownIt,
    BEGoalsList,
    SearchableDrowndownComponent,
    FilterComponent,
    PrintableDashboardComponent,
    PrintableChartComponent,
    StepIndicatorComponent,
    AddLocationModalComponent,
    SortingWidgetComponent,
    EditDropDownComponent,
    BeGaolsStatusCheckboxGroupComponent,
    StackedBarChartComponent, 
    BarChartComponent,
    PieChartComponent,
    StackedBarChartComponentAdvance,
    pipes,
    directives,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    NgbAccordionModule,
    NgbNavModule,
    ReactiveFormsModule,
    NgxChartsModule,
    EditorModule,
    NgChartsModule,
    MarkdownModule.forChild(),
  ],
  entryComponents: [SubmitConfirmationModalComponent, ModalComponent],
  providers: [
    ModalService,
    LoaderService,
    ErrorService,
    StepService,
    DatePipe,
    NgbDateParserFormatterExtProvider,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },

    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgChartsModule,
    // ChartModule,
    NavBarComponent,
    HeaderComponent,
    LoaderComponent,
    ErrorComponent,
    ToastComponent,
    GoBackComponent,
    SelectSearchableComponent,
    SearchableDrowndownComponent,
    TableComponent,
    ScrollableTableComponent,
    AnswersNavigationComponent,
    AccordionComponent,
    ClientFormComponent,
    NewClientFormComponent,
    DeepdiveChartsComponent,
    RiskScanGoalsComponent,
    RiskScanGoalDetailsComponent,
    BeGoalsIndicationsComponent,
    BeGoalRiskScanDetailsComponent,
    RiskScanLegendaComponent,
    RiskLevelsComponent,
    GenericConsultantEmailComponent,
    BeGoalSdgComponent,
    SpinnerComponent,
    ContextMenuComponent,
    SelectComponent,
    ModalComponent,
    TabComponent,
    DeepdiveQuestionsComponent,
    DeepdiveQuestionsMultipleLocationsComponent,
    DeepdiveTableQuestionsComponent,
    TextEditorComponent,
    DeepdiveAssessmentQuestionsComponent,
    LogoutComponent,
    CSRDBox,
    HomeNavigationComponent,
    AssessmentCommentsTabComponent,
    DeepdivePerformanceComponent,
    DeepdiveRegulationsComponent,
    DeepdiveGoalComponent,
    DeepdiveActionComponent,
    DeepdiveNotesComponent,
    DeepdiveAssessmentResultsComponent,
    ChartComponent,
    ChartComponentV2,
    CheckboxGroupComponent,
    MarkdownIt,
    BEGoalsList,
    FilterComponent,
    PrintableDashboardComponent,
    PrintableChartComponent,
    StepIndicatorComponent,
    AddLocationModalComponent,
    SortingWidgetComponent,
    EditDropDownComponent,
    BeGaolsStatusCheckboxGroupComponent,
    StackedBarChartComponent, 
    BarChartComponent,
    PieChartComponent,
    StackedBarChartComponentAdvance,
    pipes,
    directives,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
