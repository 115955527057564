
export const dummyGroups: any = {
    7: [
        {
            "ID": "8",
            "HEADER": "Management (General)",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": true

        },
        {
            "ID": "9",
            "HEADER": "Focus en Verdieping",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "10",
            "HEADER": "Afvalstromen",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        }
    ],
    1: [
        {
            "ID": "1",
            "HEADER": "Elektriciteit (General)",
            "DESCRIPTION": [
                "Jaarnota gekozen jaar elektriciteit",
                "Stroometiket van uw stroomleverancier"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "2",
            "HEADER": "Aardgas",
            "DESCRIPTION": [
                "Jaarnota gekozen jaar aardgas"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "3",
            "HEADER": "Brandstof",
            "DESCRIPTION": [
                "Overzicht wagenpark gekozen jaar (aantal en type bedrijfswagens)",
                "Verbruik in liters (diesel, LPG, benzine, kwh) van bedrijfswagens"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "4",
            "HEADER": "Vastgoed",
            "DESCRIPTION": [
                "Indien aanwezig: energielabel pand",
                "Oppervlakte van het (kantoor)pand"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "5",
            "HEADER": "Overige energie",
            "DESCRIPTION": [
                "Jaarnota gekozen jaar van overige energie (indien van toepassing)"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "6",
            "HEADER": "Broeikasgassen",
            "DESCRIPTION": [
                "Indien van toepassing: uitstoot (in kg) van methaan (CH4), di-stikstofmonoxide (N2O), gefluoreerde gassen, en andere broeikasgassen"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "7",
            "HEADER": "Strategie en management",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        }
    ],
    6: [
        {
            "ID": "1",
            "HEADER": "Elektriciteit (General)",
            "DESCRIPTION": [
                "Jaarnota gekozen jaar elektriciteit",
                "Stroometiket van uw stroomleverancier"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "2",
            "HEADER": "Aardgas",
            "DESCRIPTION": [
                "Jaarnota gekozen jaar aardgas"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "3",
            "HEADER": "Brandstof",
            "DESCRIPTION": [
                "Overzicht wagenpark gekozen jaar (aantal en type bedrijfswagens)",
                "Verbruik in liters (diesel, LPG, benzine, kwh) van bedrijfswagens"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "4",
            "HEADER": "Vastgoed",
            "DESCRIPTION": [
                "Indien aanwezig: energielabel pand",
                "Oppervlakte van het (kantoor)pand"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "5",
            "HEADER": "Overige energie",
            "DESCRIPTION": [
                "Jaarnota gekozen jaar van overige energie (indien van toepassing)"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "6",
            "HEADER": "Broeikasgassen",
            "DESCRIPTION": [
                "Indien van toepassing: uitstoot (in kg) van methaan (CH4), di-stikstofmonoxide (N2O), gefluoreerde gassen, en andere broeikasgassen"
            ],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "7",
            "HEADER": "Strategie en management",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        }
    ],
    2: [
        {
            "ID": "11",
            "HEADER": "Waterinvoer (General)",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "12",
            "HEADER": "Waterafvoer",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "13",
            "HEADER": "Waterbesparing",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        },
        {
            "ID": "14",
            "HEADER": "Water management",
            "DESCRIPTION": [],
            "QUESTIONS_SAVED": false
        }
    ]
}
