'use strict';

export const httpUrls = {
  //Client api's
  CUSTOMER: 'customer',
  CUSTOMER_EVENT: 'customer/event/{eventId}',
  QUESTIONS_GROUP: 'group/{groupId}',
  SUBMIT_ANSWERS: 'riskScan/answers',
  GET_RISKSCAN_RESULTS: 'riskScan',
  GET_RISKSCAN_DETAILED_RESULTS: 'riskScan/details/{breakEvenGoalId}',
  DEEPDIVE_OVERVIEW: 'deepdive/{breakEvenGoalId}',
  DEEPDIVE_REFERENCE_YEAR: 'deepdive/{breakEvenGoalId}/year',
  DEEPDIVE_GROUP_QUESTIONS: 'deepdive/group/{groupId}/year/{year}/location/{location}',
  SAVE_GROUP_DEEPDIVE_ANSWERS:
    'deepdive/answers/year/{year}/groupId/{groupId}/location/{location}',
    SUBMITDEEPDIVE_X: 'deepdive/{breakEvenGoalId}/event/{eventId}?year={year}',
    SUBMITDEEPDIVE: 'deepdive/{breakEvenGoalId}/event/{eventId}/year/{year}',
  GET_PUBLISHED_DEEPDIVE_DASHBOARD_NOTES: 'deepdive/notes/year/{year}/{breakEvenGoalId}',
  GET_PUBLISHED_DEEPDIVE_DASHBOARD_CHARTS: 'deepdive/details/year/{year}/{breakEvenGoalId}',
  GET_ALL_DEEPDIVES: 'deepdives',
  GET_CUSTOMER_DEEPDIVE_GROUPS: 'deepdive/{breakEvenGoalId}/groups/year/{year}/location/{location}',
  GET_USEFUL_DOCS: 'deepdive/{breakEvenGoalId}/documents',

  //client locations
  LOCATION_BY_BE_ID_AND_YEAR: 'deepdive/{breakEvenGoalId}/locations/year/{year}',
  LOCATION_BY_YEAR: 'deepdive/location/{location}/year/{year}',


  //consultant api's
  CUSTOMERS: 'customers',
  CONSULTANTS: 'consultants',
  SECTOR: 'sector',
  GET_CUSTOMER_DETAILS: 'customer/{customerId}',
  DELETE_CUSTOMER: 'customer/{customerId}',
  GET_RISK_SCAN_BE_GOALS: 'riskScan/{customerId}',
  GET_CUSTOMER_BE_GOAL_DETAILS:
    'riskScan/{customerId}/details/{breakEvenGoalId}',
  POST_CUSTOMER_STATUS: 'customer/{customerId}/event/{eventId}',
  RISK_SCAN_NOTES: 'riskScan/{customerId}/notes/{breakEvenGoalId}',
  CHANGE_RISKSCAN_ANSWERS: 'riskScan/answers/{customerId}',
  MODIFY_GROUP_DEEPDIVE_ANSWERS:
    'deepdive/{breakEvenGoalId}/{customerId}/answers/{year}',
  CONSULTANT_CUSTOMER_DEEPDIVE_OVERVIEW_X: 'deepdive/customer/{customerId}',
  CONSULTANT_CUSTOMER_DEEPDIVE_OVERVIEW: 'deepdive/customer/{customerId}/overview/{year}',
  CONSULTANT_CUSTOMER_DEEPDIVE_DETAILS:
  'deepdive/customer/{customerId}/group/{groupId}/year/{year}',
    // 'deepdive/customer/{customerId}/group/{id}/{breakEvenGoalId}',
    // /deepdive/customer/{customerId}/groups/{breakEvenGoalId}/year/{year}/location/{location}
  DEEPDIVE_NOTES: 'deepdive/customer/{customerId}/notes/{breakEvenGoalId}',
  ADD_UPDATE_DELETE_DEEPDIVE_NOTES: 'deepdive/customer/{customerId}/notes/year/{year}/{breakEvenGoalId}',
    //  /deepdive/customer/{customerId}/notes/{breakEvenGoalId}/year/{year}/similar
  DEEPDIVE_RECOMENDATIONS: 'deepdive/customer/{customerId}/notes/{breakEvenGoalId}/year/{year}/similar',
  //  /deepdive/customer/{customerId}/notes/{breakEvenGoalId}/year/{year}/section/{section}:
  GET_CONSULTANT_SECTION_NOTES:
    'deepdive/customer/{customerId}/notes/{breakEvenGoalId}/year/{year}/section/{section}',
  PUBLISH_DEEPDIVE: 'deepdive/{breakEvenGoalId}/{customerId}/event/{eventId}/year/{year}',
  GET_CHARTS_X: 'deepdive/customer/{customerId}/details/{breakEvenGoalId}',
  GET_CHARTS: 'deepdive/customer/{customerId}/details/year/{year}/{breakEvenGoalId}',
  CHANGE_DEEPDIVE_ANSWERS:
    'deepdive/customer/{customerId}/answers/{breakEvenGoalId}/year/{year}/groupId/{groupId}',
  GET_CONSULTANT_DEEPDIVE_GROUPS:
    'deepdive/groups/{breakEvenGoalId}',
    //   /deepdive/customer/{customerId}/groups/{breakEvenGoalId}/year/{year}/location/{location}:
    GET_CONSULTANT_DEEPDIVE_GROUPS_XX:
    'deepdive/customer/{customerId}/group/{breakEvenGoalId}/year/{year}',
    GET_NUMBER_OF_DEEPDIVE_LOCATIONS_BY_YEAR:
    'deepdive/customer/{customerId}/locations/year/{year}',
  CSRD_EVENT: 'csrd/event/{eventId}/year/{year}',
};
