// step-indicator.component.ts
import { Component, OnInit, Input } from '@angular/core';
import { getNumberOfAllowedLocations } from 'src/app/modules/client/utils';
import { StepService } from 'src/app/shared/services/step.service';
const INDICATOR_STEPS = [
  {
    indicatorText: 1,
    indicatorLabel: "Selecteer jaar",
  },
  {
    indicatorText: 2,
    indicatorLabel: "Selecteer vestigingen",
  },
  {
    indicatorText: 3,
    indicatorLabel: "Vragenlijst",
  }
];

const INDICATOR_STEPS_SINGLE_LOCATION = [
  {
    indicatorText: 1,
    indicatorLabel: "Selecteer jaar",
  },
  {
    indicatorText: 2,
    indicatorLabel: "Vragenlijst",
  }
];

const INDICATOR_STEPS_CONSULTANT = [
  {
    indicatorText: 1,
    indicatorLabel: "Resultaten bekijken",
  },
  {
    indicatorText: 2,
    indicatorLabel: "Notities toevoegen",
  },
  {
    indicatorText: 3,
    indicatorLabel: "Publiceren",
  }
];
@Component({
  selector: 'app-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})

export class StepIndicatorComponent implements OnInit {

  @Input() indicatorSteps!: any;
  @Input() indicatorStepsType!: any;

  constructor(public stepService: StepService) { }

  ngOnInit(): void {
    if(!this.indicatorSteps ){
      if(!this.indicatorStepsType) {
        let numberOfAllowedLocations = getNumberOfAllowedLocations();
        this.indicatorSteps =  numberOfAllowedLocations == 1 ? INDICATOR_STEPS_SINGLE_LOCATION : INDICATOR_STEPS;
      } else {
        this.indicatorSteps = INDICATOR_STEPS_CONSULTANT;
      }

    
    }

  }
}
