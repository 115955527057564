<div
  [class]="toastService.toastState$ | async"
  [@toastTrigger]="(toastService.showsToast$ | async) ? 'open' : 'close'"
>
  <!--We bind it by surrounding 'class' with square-brackets and referencing the property from the template-->
  <div class="d-flex toast-info-block">
    <img
      src="../../../assets/images/{{ toastService.toastState$ | async }}.svg"
      alt="Toast Icon"
    />
    <!--We access the toastMessage$ observable in the service-->
    <span class="px-3 message">{{ toastService.toastMessage$ | async }}</span>

    <img
      src="../../../assets/images/cross-black.svg"
      alt="Cancel"
      (click)="dismiss()"
      class="cursor cancel"
    />
  </div>
</div>
