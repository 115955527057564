import { BE_GOALS } from 'src/app/shared/utilities/constants';
export const ENUMS = {
    PUBLISHED: "Gepubliceerd",
    ACTION_REQUIRED: "Actie vereist",
    IN_PROGRESS: "Gestart",
    NOT_STARTED_YET: "Nog niet gestart",
}

export const SORT_ORDER = {
    ASC: 'A-Z', // default
    DES: 'Z-A',
}

export function sortAlphabetically({ data, key, sortingOrder }: any) {
    return data.sort((a: any, b: any) => {
        const firstValue = typeof a[key] === 'string' ? a[key]?.toLowerCase() : a[key];
        const secondValue = typeof b[key] === 'string' ? b[key]?.toLowerCase() : b[key];

        if (firstValue < secondValue) {
            return sortingOrder === SORT_ORDER.DES ? -1 : 1;
        }
        if (firstValue > secondValue) {
            return sortingOrder === SORT_ORDER.DES ? 1 : -1;
        }
        return 0;
    });
}

export function getCursorClass(status: string) {

    if (status != ENUMS.IN_PROGRESS && status!= ENUMS.NOT_STARTED_YET) {
        return 'cursor';
    }
    return 'non-clickable';

}

export function getStatusHeading(data: any[]): any {
    let statusHeading = data[0]?.status || null;
    let statusArray = data.map((state) => state.status);
    if(statusArray.includes(ENUMS.ACTION_REQUIRED)){
      statusHeading = ENUMS.ACTION_REQUIRED;
      return statusHeading;
    } else if(statusArray.includes(ENUMS.NOT_STARTED_YET)){
      statusHeading = ENUMS.NOT_STARTED_YET;
      return statusHeading;
    } else if(statusArray.includes(ENUMS.IN_PROGRESS)){
      statusHeading = ENUMS.IN_PROGRESS;
      return statusHeading;
    }
    return statusHeading;
  }

  export function getStatusClass(status: string): string {
    if (status == ENUMS.PUBLISHED) {
      return "published"
    } else if (status == ENUMS.ACTION_REQUIRED) {
      return "action-required"
    }
    return "in-progress";
  }

  export function getIconSource(id: number) {
    return BE_GOALS?.filter((item) => item?.BREAK_EVEN_GOAL_ID == id)[0]?.ICON;
  }

  export function openNewTab(id: number, client: any, year:any) {
    const baseUrl = window.location.origin;
    const featureUrl = `${baseUrl}/consultants/selected-client/${client?.ID}`;
    const redirectUrl = id ? `${featureUrl}/assessments?breakEvenGoalId=${id}&year=${year}` : `${featureUrl}/riskScan`
    window.open(redirectUrl, "_blank");
  }

  export function getStatus(status: any) {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus.includes('published')) {
      return ENUMS.PUBLISHED;
    } else if (lowerCaseStatus.includes('completed') || lowerCaseStatus.includes('submitted')) {
      return ENUMS.ACTION_REQUIRED;
    } else if (lowerCaseStatus.includes('not_started')) {
      return ENUMS.NOT_STARTED_YET;
    }
    return ENUMS.IN_PROGRESS;
  }

  export function getDeepDiveStateName(stateId: any) {
    return BE_GOALS.filter((item) => item.BREAK_EVEN_GOAL_ID == stateId)[0]?.TITLE
  }