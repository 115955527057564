export const getCustomer = () => {
    let customer = sessionStorage.getItem("customer");
    if (customer) {
        return JSON.parse(customer);
    }
    return null;
}

export const getNumberOfAllowedLocations = () => {
    let customer = getCustomer();
    if (customer) {
        return customer?.NUMBER_OF_LOCATIONS;
    }
    return 1;
}

export const getCompanyName = () => {
    let customer = getCustomer();
    if (customer) {
        return customer?.COMPANY_NAME;
    }
    return 1;
}


export const setCustomer = (customer: any) => {
    if (customer) {
        let serializedCustomer = JSON.stringify(customer);
        sessionStorage.setItem("customer", serializedCustomer);
    }
}

export const getDeepdiveState = (deepdiveId: string | number, year: string | number) => {
    let customer = getCustomer();
    if (customer) {
        let deepDive = customer?.DEEPDIVE_STATES?.[year]?.filter((deepDiveState: any) => Number(deepDiveState.BREAK_EVEN_GOAL_ID) === Number(deepdiveId))[0];
        return deepDive?.DEEPDIVE_STATE;
    }
    return null;
}

export const updateDeepDiveState = (deepdiveId: string | number, year: string | number, newState: string) => {
    let customer = getCustomer();
    if (customer) {
        let updatedCustomer = customer?.DEEPDIVE_STATES?.[year]?.map((deepDiveState: any) => {
            if (Number(deepDiveState.BREAK_EVEN_GOAL_ID) === Number(deepdiveId)) {
                deepDiveState.DEEPDIVE_STATE = newState;
            }
            return deepDiveState;
        })
        setCustomer(updatedCustomer);
    }
}